import React from "react"
import styled from "styled-components"

const StyledTag = styled.span`
  cursor: ${({ isActive }) => (isActive ? "pointer" : "default")};
`

const SelectableTag = ({ isSelected, isActive, children, onClick }) => {
  let className = `tag is-medium `
  if (!isActive) {
    className += "is-dark"
  } else {
    className += isSelected ? "is-primary" : "is-white"
  }

  return (
    <StyledTag
      className={className}
      isActive={isActive}
      onClick={isActive ? onClick : undefined}
    >
      {children}
    </StyledTag>
  )
}

export default SelectableTag
