import React from "react"
import moment from "moment"
import { FormattedMessage } from "react-intl"

const Cart = ({ tag }) => {
  return (
    <div className="columns">
      <div className="column">
        <div className="field">
          <div className="label">
            <FormattedMessage id="Cart.label.workout" />
          </div>
          <div>
            {moment(tag.dateTime).format("DD MMM HH:mm")} - {tag.name}
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <div className="label">
            <FormattedMessage id="Cart.label.duration" />
          </div>
          <div>
            <FormattedMessage id="Cart.label.duration.value" />
          </div>
        </div>
      </div>
      <div className="column">
        <div className="field">
          <div className="label">
            <FormattedMessage id="Cart.label.price" />
          </div>
          <div>
            <FormattedMessage id="Cart.label.price.value" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
