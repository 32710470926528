import React from "react"
import SelectableTag from "./SelectableTag"
import moment from "moment"
import styled from "styled-components"
import { BLUE } from "../constants/style"

const StyledTagBox = styled.div`
  margin-bottom: 20px;
`

const SelectorContainer = styled.div`
  overflow-y: scroll;
  height: 400px;
  /* width */

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #d9d9d9;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: ${BLUE};
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

function groupByDate(group, tag) {
  const key = tag.date
  if (group[key]) {
    group[key].push(tag)
  } else {
    group[key] = [tag]
  }

  return group
}

const WorkoutSelector = ({ onTagSelect, selectedTag, tags }) => {
  const groupedTags = tags
    .map(tag => ({
      ...tag,
      date: moment(tag.date).format("ddd DD.MM.YYYY"),
      time: moment(tag.date).format("HH:mm"),
      dateTime: moment(tag.date),
    }))
    .reduce(groupByDate, {})

  Object.values(groupedTags).forEach(group =>
    group.sort((tagA, tagB) => tagA.dateTime - tagB.dateTime)
  )

  const sortedTagDates = Object.values(groupedTags)
    .map(([tag]) => tag)
    .sort((tagA, tagB) => tagA.dateTime - tagB.dateTime)

  return (
    <SelectorContainer>
      {sortedTagDates.map(tag => (
        <StyledTagBox key={tag.dateTime}>
          <h6 className="label">{tag.date}</h6>
          <div className="tags">
            {(groupedTags[tag.date] || []).map(tag => (
              <SelectableTag
                key={tag.id}
                isActive={tag.dateTime > moment()}
                isSelected={selectedTag && selectedTag.id === tag.id}
                onClick={onTagSelect(tag)}
              >
                {tag.time} - {tag.name}
              </SelectableTag>
            ))}
          </div>
        </StyledTagBox>
      ))}
    </SelectorContainer>
  )
}

export default WorkoutSelector
