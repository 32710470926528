const hookURL = "https://hooks.zapier.com/hooks/catch/6204374/oxfau8o/"

export const withEmailSend = () => {
  const send = data =>
    fetch(hookURL, {
      method: "POST",
      body: JSON.stringify(data),
    })

  return [send]
}
