import React, { useState } from "react"
import Button from "../elements/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import Cart from "../elements/Cart"
import { FormattedHTMLMessage, FormattedMessage } from "react-intl"
import { withEmailSend } from "../hooks/withEmailSend"
import AGB from "../files/Allgemeine Geschäftsbedingungen.pdf"

const StyledSuccessBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  span {
    margin-left: 14px;
  }
`

const SignUpForm = ({ tag, selectTag }) => {
  const [send] = withEmailSend()
  const [submissionState, setSubmissionState] = useState("default")
  const [formState, setFormState] = useState({})

  const setFromField = event => {
    const name = event.currentTarget.name
    const value = event.currentTarget.value
    setFormState(state => ({
      ...state,
      [name]: value,
    }))
  }

  const setFormCheckbox = () => {
    setFormState(state => ({
      ...state,
      agree: !state.agree,
    }))
  }

  const isDisabled = !(
    formState["firstName"] &&
    formState["lastName"] &&
    formState["email"] &&
    formState["agree"] &&
    tag
  )

  const submit = event => {
    event.preventDefault()
    const data = {
      ...formState,
      workout: `${tag.dateTime.format("HH:MM DD.MM.YYYY")} ${tag.name}`,
    }
    setSubmissionState("sending")
    send(data).finally(() => {
      setFormState({})
      selectTag(undefined)
      setSubmissionState("done")
      setTimeout(() => {
        setSubmissionState("default")
      }, 1000)
    })
  }

  return (
    <>
      <StyledSuccessBox
        className={`has-text-primary title is-size-1 ${
          submissionState === "done" ? "" : "is-hidden"
        }`}
      >
        <FontAwesomeIcon icon={faCheck} className="is-primary" />
        <span>
          <FormattedMessage id="Form.success" />
        </span>
      </StyledSuccessBox>
      <div>
        {!!tag && <Cart tag={tag} />}
        <form
          onSubmit={submit}
          className={submissionState === "done" ? "is-hidden" : undefined}
          autoComplete="on"
        >
          <div className="field  is-grouped is-fullwidth">
            <div className="control is-expanded">
              <label className="label">
                <FormattedMessage id="Form.label.firstName" />
              </label>
              <input
                required
                className="input"
                type="text"
                autoComplete="given-name"
                name={"firstName"}
                value={formState["firstName"] || ""}
                onChange={setFromField}
              />
            </div>
            <div className="control is-expanded">
              <label className="label">
                <FormattedMessage id="Form.label.lastName" />
              </label>
              <input
                required
                className="input"
                type="text"
                autoComplete="family-name"
                name={"lastName"}
                value={formState["lastName"] || ""}
                onChange={setFromField}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="Form.label.email" />
            </label>
            <div className="control">
              <input
                required
                className="input"
                type="email"
                name={"email"}
                value={formState["email"] || ""}
                onChange={setFromField}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">
              <FormattedMessage id="Form.label.phone" />
            </label>
            <div className="control">
              <input
                className="input"
                type="tel"
                autoComplete="tel"
                name={"phone"}
                value={formState["phone"] || ""}
                onChange={setFromField}
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input
                  required
                  type="checkbox"
                  name={"agree"}
                  value={formState["agree"] || false}
                  onChange={setFormCheckbox}
                />{" "}
                <FormattedMessage
                  id="Form.label.iAgree"
                  values={{
                    name: (
                      <a href={AGB} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="Footer.terms-and-conditions" />
                      </a>
                    ),
                  }}
                />{" "}
              </label>
            </div>
          </div>

          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <Button
                disabled={isDisabled}
                type="submit"
                className={`button is-primary is-outlined is-medium is-radiusless is-family-secondary is-uppercase ${
                  submissionState === "sending" ? "is-loading" : ""
                } ${isDisabled ? "is-disabled" : ""}`}
              >
                <FormattedMessage id="OnlineOffer.button" />
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default SignUpForm
