import "../styles/index.scss"
import React from "react"
import I18n from "../components/I18n"
import Footer from "../components/Footer"
import { AppContextProvider } from "../contexts/appContext"
import Navbar from "../components/Navbar"
import OnlineOffer from "../components/OnlineOffer"
import Head from "../components/Head"
import { IntlProvider } from "react-intl"
import en from "../i18n/en.json"
import de from "../i18n/de.json"
import ru from "../i18n/ru.json"

const messagesByLocale = {
  en,
  de,
  ru,
}

export const OnlineWorkoutsPageTemplate = ({ messages, locale }) => {
  return (
    <IntlProvider
      locale={locale}
      messages={{ ...messages, ...messagesByLocale[locale] }}
    >
      <AppContextProvider locale={locale} pagePath={""}>
        <Head />
        <Navbar />
        <OnlineOffer />
        <Footer />
      </AppContextProvider>
    </IntlProvider>
  )
}

export default ({ pageContext }) => {
  return (
    <I18n locale={pageContext.locale} page="/online-workout">
      <AppContextProvider
        locale={pageContext.locale}
        pagePath={"/online-workout"}
      >
        <Head />
        <Navbar />
        <OnlineOffer />
        <Footer />
      </AppContextProvider>
    </I18n>
  )
}
