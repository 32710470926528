import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import Title from "../elements/Title"
import styled from "styled-components"
import SignUpForm from "./SignUpForm"
import WorkoutSelector from "./WorkoutSelector"
import SignUpSteps from "../elements/SignUpSteps"
import { graphql, useStaticQuery } from "gatsby"

const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`

const StyledTitle = styled.span`
  width: 100%;
  align-self: flex-start;
  margin-bottom: 2em;
`

const OnlineOffer = () => {
  const [tag, selectTag] = useState()
  let tags = []
  try {
    tags = useStaticQuery(graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { name: { ne: "TEST_NOT_RENDER" } } }
        ) {
          nodes {
            frontmatter {
              name
              date
            }
          }
        }
      }
    `).allMarkdownRemark.nodes.map(({ frontmatter }) => ({
      ...frontmatter,
      id: frontmatter.date,
    }))
  } catch {}

  const selectTagHandle = tag => () => selectTag(tag)
  return (
    <React.Fragment>
      <section className="section is-small">
        <div>
          <h1 className="is-size-3 has-text-left shifted">
            <Title>
              <FormattedMessage id="OnlineOffer.title" />
            </Title>
          </h1>
        </div>
      </section>
      <section className="section is-small">
        <div className="subtitle">
          <FormattedMessage id="OnlineOffer.intro" />
        </div>
      </section>
      <section className="section is-small">
        <SignUpSteps />
      </section>
      <section className="section is-small has-background-primary">
        <div className="container">
          <div className="notification">
            <div className="columns">
              <div className="column">
                <TextContainer>
                  <StyledTitle>
                    <h4 className="title is-4">
                      <FormattedMessage id="OnlineOffer.timetable.title" />
                    </h4>
                    <WorkoutSelector
                      onTagSelect={selectTagHandle}
                      selectedTag={tag}
                      tags={tags}
                    />
                  </StyledTitle>
                </TextContainer>
              </div>
              <div className="column has-text-justified">
                <TextContainer>
                  <StyledTitle>
                    <h4 className="title is-4">
                      <FormattedMessage id="OnlineOffer.form.title" />
                    </h4>
                    <SignUpForm tag={tag} selectTag={selectTag} />
                  </StyledTitle>
                </TextContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default OnlineOffer
