import React from "react"
import Step from "./Step"
import { FormattedMessage } from "react-intl"

const SignUpSteps = () => {
  return (
    <div className="columns">
      <div className="column">
        <Step
          stepNumber={1}
          text={<FormattedMessage id="SignUpSteps.step1" />}
        />
      </div>
      <div className="column">
        <Step
          stepNumber={2}
          text={<FormattedMessage id="SignUpSteps.step2" />}
        />
      </div>
      <div className="column">
        <Step
          stepNumber={3}
          text={<FormattedMessage id="SignUpSteps.step3" />}
        />
      </div>
    </div>
  )
}

export default SignUpSteps
