import React from "react"
import styled from "styled-components"
import { BLUE, WHITE } from "../constants/style"

const StyledNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: ${WHITE};
  background: ${BLUE};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 0.6rem;
  margin-bottom: 0.6rem;
`

const StyledContainer = styled.div`
  padding: 1rem;
`

const Step = ({ stepNumber, text }) => {
  return (
    <StyledContainer>
      <StyledNumber className="is-family-secondary">{stepNumber}</StyledNumber>
      <div>{text}</div>
    </StyledContainer>
  )
}

export default Step
